<template>
  <section class="streaming-program" ref="container">
    <alert-window :isVisible="showCheckKickPCAlert" :type="'confirm'" :message="$t('streamingVideo.key031')" @confirm="handleCheckKickPCAlertConfirm" @cancel="handleCheckKickPCAlertCancel"></alert-window>
    <app-header></app-header>
    <div class="program-wrapper">
      <div class="video-area">
        <div class="program-info" v-show="isDefaultMode">
          <!-- 節目概述 -->
          <div v-show="!showMoreInfo" class="program-summary">
            <program-summary
              :showMoreInfo="showMoreInfo"
              :streamingInfo="streamingInfo"
              :infoExpand="infoExpand"
              @toggleShowMore="toggleShowMore"
              @toggleShowChatRoom="toggleShowChatRoom"
            >
            </program-summary>
          </div>
          <!-- 節目詳細說明 -->
          <div v-show="showMoreInfo">
            <program-details @toggleShowMore="toggleShowMore" :streamingInfo="streamingInfo"> </program-details>
          </div>
        </div>
        <div class="video-container" style="width: 100%; height: 100%">
          <player-page v-if="streamingInfo != null && !showCheckKickPCAlert" :streamingInfo="streamingInfo" @refresh-streaming-info="fetchStreamingInfo" style="width: inherit; height: inherit">
          </player-page>
        </div>
      </div>

      <!-- 聊天室 -->
      <chat-room
        v-show="showChatRoom"
        v-if="hasValidTicket"
        :scs="userItemsScs"
        :stickers="userItemsStickers"
        @getUserItems="getUserItems"
        @toggle-showChatRoom="toggleShowChatRoom"
        @toggle-showLoginModal="toggleShowLoginModal"
      >
      </chat-room>

      <!-- 說明Title_Mobile -->
      <div class="title-info" v-if="isDescriptionMode_Mobile">
        <p>{{ $t('streamingVideo.key001') }}</p>
        <i class="bi bi-x leave-icon" @click="toggleShowMore"></i>
      </div>

      <!-- 詳細說明_Mobile -->
      <div v-show="isDescriptionMode_Mobile" class="modal-overlay-mobile">
        <program-details @toggleShowMore="toggleShowMore" :streamingInfo="streamingInfo"></program-details>
      </div>
    </div>

    <section class="member-login streaming-chatRoom-Login" v-if="showLoginModal">
      <div class="mask" @click="closeLoginComponent"></div>
      <div class="login-component">
        <login @next="afterLogin" :nextPath="`streaming/${cid}`" :directions="$t('streamingVideo.key012')"></login>
      </div>
    </section>
  </section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, provide, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { getStreamingInfo, getUserItems as getUserItemsApi, kickPCUser, checkPCLoginStatus } from '@/api/streamingService.js';
import { checkLogin, getUserInfo } from '@/api/apiService.js';
import AppHeader from '@/components/Header.vue';
import ProgramSummary from '@/pages/programVideo/programInfo/ProgramSummary.vue';
import ProgramDetails from '@/pages/programVideo/programInfo/ProgramDetails.vue';
import PlayerPage from '@/pages/programVideo/video/PlayerPage.vue';
import ChatRoom from '@/pages/programVideo/chatRoom/ChatRoom.vue';
import Login from '@/pages/login/Login.vue';
import AlertWindow from '@/components/AlertWindow.vue';
import { HttpStatusCode } from 'axios';

const store = useStore();
const router = useRouter();
const { t } = useI18n();
const UPDATE_MARQUEES_INTERVAL = 10000;
const CHECK_LOGIN_INTERVAL = 10000;

provide('isListeningNext', true);

const isCheckingLogin = ref(false);
const checkLoginInterval = ref(null);
const updateMarqueesInterval = ref(null);
const streamingInfo = ref(null);
const showLoginModal = ref(false);
const isAlertShowed = ref(false);
const infoExpand = ref(false);
const showCheckKickPCAlert = ref(false);
const hasValidTicket = ref(false);

const userItemsScs = ref([]);
const userItemsStickers = ref([]);

const showChatRoom = ref(true);
const showMoreInfo = ref(false);

const currentLanguage = computed(() => store.state.currentLanguage);
const jwt = computed(() => store.state.jwt);
const isMobile = computed(() => store.state.isMobile);
const isDefaultMode = computed(() => (!showChatRoom.value && !showMoreInfo.value) || !isMobile.value);
const isDescriptionMode_Mobile = computed(() => showMoreInfo.value && isMobile.value);

const cid = computed(() => router.currentRoute.value.params.cid);

// workaround for router.currentRoute.value.params.cid is not updated
watch(cid, () => {
  window.location.reload();
});

//Calculate Full-Screen Height for Mobile
const container = ref(null);

const setDynamicHeight = () => {
  if (container.value) {
    container.value.style.height = `${window.innerHeight}px`;
  }
};

onMounted(async () => {
  setDynamicHeight();
  window.addEventListener('resize', setDynamicHeight);

  await fetchStreamingInfo();

  if (streamingInfo.value?.needLogin) {
    if (!jwt.value) {
      router.push(`/${currentLanguage.value}/member-login/login`);

      setTimeout(() => {
        alert(t('streamingVideo.key023'));
      }, 500);
      return;
    }

    const checkPCLoginStatusResponse = await checkPCLoginStatus();

    if (checkPCLoginStatusResponse.data.data.isLogin) {
      showCheckKickPCAlert.value = true;

      return;
    }
  }
  
  await userInfoInit();
  showLoginModal.value = false;

  updateMarqueesInterval.value = setInterval(() => {
    store.dispatch('updateMarquees', router.currentRoute.value);
  }, UPDATE_MARQUEES_INTERVAL);
});

const handleCheckKickPCAlertConfirm = async () => {
  await kickPCUser();
  showCheckKickPCAlert.value = false;

  await userInfoInit();
}

const handleCheckKickPCAlertCancel = () => {
  showCheckKickPCAlert.value = false;
  router.back();
}

onBeforeUnmount(() => {
  window.removeEventListener('resize', setDynamicHeight);

  removeCheckLoginInterval();

  if (updateMarqueesInterval.value) {
    clearInterval(updateMarqueesInterval.value);
    updateMarqueesInterval.value = null;
  }
});

const userInfoInit = async () => {
  // 如果已經登入, 先取得 user info & 道具 (檢查是否有填基本資訊)
  if (jwt.value) {
    await getUserInfo(jwt.value, true);
    await getUserItems();
  }
}

const removeCheckLoginInterval = () => {
  if (checkLoginInterval.value) {
    clearInterval(checkLoginInterval.value);
    checkLoginInterval.value = null;
  }
};

const fetchStreamingInfo = async () => {
  try {
    const langSetting = currentLanguage.value === 'zh_tw' ? 'zh-tw' : currentLanguage.value;
    const response = await getStreamingInfo(cid.value, langSetting);
    const data = response.data.data;
    
    if (!data.isActive) {
      alert(t('streamingVideo.key026'));
      router.replace({ path: `/${currentLanguage.value}/` });
      return;
    }

    streamingInfo.value = data;
    
    if (jwt.value) {
      checkLoginInterval.value = setInterval(checkUserLogin, CHECK_LOGIN_INTERVAL);
    }
  } catch (error) {
    console.error('Error fetching streaming info:', error);
    alert(t('streamingVideo.key026'));
    router.replace({ path: `/${currentLanguage.value}/` });
  }
};

/**
 * 不需登入才能觀看的頁面, 重複登入時, 不跳轉到登入頁面, 在原頁面 alert 後彈出 login modal
 *
 * 需要登入才能觀看的頁面, 重複登入時, 跳轉到登入頁面之後 alert
 */
const handleCompetedLogin = () => {
  if (!streamingInfo.value?.needLogin) {
    showAlert(t('streamingVideo.key025'));

    removeCheckLoginInterval();
    showLoginModal.value = true;

    // not to redirect to login page (occurred by axios interceptor)
    router.replace({ path: `/${currentLanguage.value}/streaming/${cid.value}` });
  } else {
    setTimeout(() => {
      showAlert(t('streamingVideo.key025'));
    }, 500);
  }
};

const showAlert = (message) => {
  if (!isAlertShowed.value) {
    alert(message);
    isAlertShowed.value = true;
  }
};

const checkUserLogin = async () => {
  try {
    if (isCheckingLogin.value) {
      return;
    }

    isCheckingLogin.value = true;
    await checkLogin();
  } catch (error) {
    if (error?.response?.status === HttpStatusCode.Unauthorized) {
      handleCompetedLogin();
    }
  } finally {
    isCheckingLogin.value = false;
  }
};

provide('handleCompetedLogin', handleCompetedLogin);

const toggleShowMore = () => {
  showMoreInfo.value = !showMoreInfo.value;
};
const toggleShowLoginModal = () => {
  showLoginModal.value = !showLoginModal.value;
};
const toggleShowChatRoom = () => {
  showChatRoom.value = !showChatRoom.value;
};
const closeLoginComponent = () => {
  showLoginModal.value = false;
};
const afterLogin = () => {
  closeLoginComponent();

  getUserItems();

  isAlertShowed.value = false;
  checkLoginInterval.value = setInterval(checkUserLogin, CHECK_LOGIN_INTERVAL);
};

const getUserItems = async () => {
  if (!jwt.value) {
    return;
  }

  try {
    const response = await getUserItemsApi(jwt.value);
    const responseData = response.data.data;
    userItemsScs.value = responseData.scs || [];
    userItemsStickers.value = responseData.stickers || [];

    const requiredTickets = streamingInfo.value?.ticketCheck || [];
    if (requiredTickets.length > 0) {
      const userTickets = responseData.tickets.map((ticket) => ticket.id) || [];
      const hasValidTicket = requiredTickets.some((ticket) => userTickets.includes(ticket));

      if (!hasValidTicket) {
        router.push({
          path: `/${currentLanguage.value}/product-info/${streamingInfo.value?.fallbackTicketProductId}`,
          query: { source: 'streaming' },
        });
        return;
      }
    }
    hasValidTicket.value = true;
  } catch (error) {
    if (error?.response?.status === HttpStatusCode.Unauthorized && typeof handleCompetedLogin === 'function') {
      handleCompetedLogin();
    }
    console.error('Error fetching get SC info:', error);
  }
};

</script>

<style lang="scss">
@import '@/assets/styles/pages/memberCenter/streamingProgram.scss';
</style>
