<template>
  <section class="privacy_page">
    <h1>{{ $t('privacy.key001') }}</h1>
    <p>
      {{ $t('privacy.key002') }}
      <a href="https://www.htc.com/tw/terms/privacy/" target="_blank">
        <strong>{{ $t('privacy.key003') }}</strong>
      </a>
      {{ $t('privacy.key004') }}
    </p>
    <h2>{{ $t('privacy.key005') }}</h2>
    <p>{{ $t('privacy.key006') }}</p>
    <h2>{{ $t('privacy.key007') }}</h2>
    <p>{{ $t('privacy.key008') }}</p>
    <h2>{{ $t('privacy.key012') }}</h2>
    <p>{{ $t('privacy.key013') }}</p>
    <h2>{{ $t('privacy.key014') }}</h2>
    <p v-html="parseRichText($t('privacy.key015'))" />
    <h2>{{ $t('privacy.key016') }}</h2>
    <p>{{ $t('privacy.key017') }}</p>
    <h2>{{ $t('privacy.key018') }}</h2>
    <p v-html="parseRichText($t('privacy.key019'))" />
    <h2>{{ $t('privacy.key020') }}</h2>
    <p v-html="parseRichText($t('privacy.key021'))" />
    <h2>{{ $t('privacy.key022') }}</h2>
    <p v-html="formattedKey023"></p>
    <p>{{ $t('privacy.key011') }}</p>
    <br />
    <router-link :to="{ path: `/${currentLanguage}/legal/legal-versions`, query: { legal: 'privacy' } }" class="legal-versions">
      <h2>{{ $t('legal.key007') }}</h2>
    </router-link>
  </section>
</template>
<script>
export default {
  name: 'LegalPage',
  components: {},
  mounted() {},
  data() {
    return {};
  },
  props: {},
  computed: {
    privacyLink() {
      const links = {
        zh_tw: 'https://www.htc.com/tw/terms/privacy/',
        en: 'https://www.htc.com/us/terms/privacy/',
        ja: 'https://www.htc.com/jp/terms/privacy/ ',
      };
      return links[this.currentLanguage] || links.en;
    },
    formattedKey023() {
      return this.$t('privacy.key023', {
        globalPrivacyMail: '<a href="mailto:global-privacy@htc.com">global-privacy@htc.com</a>',
      });
    },
  },
  methods: {
    parseRichText(text) {
      const lines = text.split('\n').map((line) => line.trim());
      return lines.join('<br />');
    },
  },
};
</script>

<style lang="scss"></style>
