<template>
  <div class="super-chat-expanded" v-if="selectedItem">
    <div class="super-chat-title">
      <div class="left-flex">
        <img src="@/assets/img/member/program/super_chat_cost.svg" alt="" class="cost-icon" />
        <span class="title">{{ $t('streamingVideo.key005') }}</span>
      </div>
      <div class="right-flex">
        <i class="bi bi-x" @click="closeSuperChatArea"></i>
      </div>
    </div>
    <div class="super-chat-select">
      <div class="item" :class="`background-${selectedItem.scId}`">
        <img :src="getImageUrl(selectedItem.scId)" alt="Icon" class="SC-icon" />
        <div class="details">
          <p>{{ selectedItem.name }}</p>
          <p>
            {{ $t('streamingVideo.key007') }}<span>{{ selectedItem.qty }}</span>
          </p>
        </div>
        <div class="price"><span class="fiat-currency">NT$</span>{{ selectedItem.price }}</div>
      </div>
    </div>
    <div class="slider-container" ref="sliderContainer" @click="onSliderClick">
      <div class="progress-bar">
        <div class="progress" :style="{ width: progressPercentage + '%' }"></div>
      </div>
      <div class="slider-markers">
        <div v-for="(_, index) in markers" :key="index" class="slider-marker"></div>
      </div>
      <div class="slider-knob" :style="{ left: progressPercentage + '%' }" @mousedown="startDragging" @touchstart="startDragging"></div>
    </div>
  </div>
</template>

<script>
import { getSCInfo } from '@/api/streamingService.js';

export default {
  name: 'SuperChatArea',
  emits: ['close-superChat', 'update-select-sc-id'],
  props: {
    scs: Array,
    default: () => [],
  },
  data() {
    return {
      currentValue: 0,
      isDragging: false,
      SCInfo: [],
    };
  },
  computed: {
    maxValue() {
      return this.SCInfo.length - 1;
    },
    markers() {
      return Array(this.SCInfo.length - 1).fill(null);
    },
    selectedItem() {
      return this.SCInfo[Math.min(this.currentValue, this.maxValue)];
    },
    progressPercentage() {
      return (this.currentValue / this.maxValue) * 100;
    },
  },
  mounted() {
    this.getSCInfo();
  },
  methods: {
    async getSCInfo() {
      try {
        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const response = await getSCInfo(langSetting);
        this.SCInfo = response.data.data;

        this.updateSCInfoWithQty(this.SCInfo, this.scs);
      } catch (error) {
        console.error('Error fetching get SC info:', error);
      }
    },
    //Add SC-Qty
    updateSCInfoWithQty(SCInfo, holdingSCs) {
      const updatedSCInfo = SCInfo.map((sc) => {
        const matchedSC = holdingSCs.find((item) => item.id === sc.scId);
        return {
          ...sc,
          qty: matchedSC ? matchedSC.qty : 0,
        };
      });

      this.SCInfo = updatedSCInfo;

      this.$emit('update-select-sc-id', this.selectedItem.scId, this.selectedItem.price);
    },
    //After send SC-Msg Update SC-Qty
    clickUpdateScQty() {
      this.updateSCInfoWithQty(this.SCInfo, this.scs);
    },
    onSliderClick(event) {
      const clientX = event.clientX;
      const offsetX = clientX - this.$refs.sliderContainer.getBoundingClientRect().left;
      const percentage = Math.min(Math.max(offsetX / this.$refs.sliderContainer.offsetWidth, 0), 1);

      // Calculate the nearest index based on the click position
      const nearestIndex = Math.round(percentage * this.maxValue);
      this.currentValue = nearestIndex;
      this.$emit('update-select-sc-id', this.selectedItem.scId, this.selectedItem.price);
    },
    startDragging(event) {
      this.isDragging = true;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('touchmove', this.onMouseMove);
      document.addEventListener('mouseup', this.stopDragging);
      document.addEventListener('touchend', this.stopDragging);
      event.preventDefault();
    },
    stopDragging() {
      if (this.isDragging) {
        this.isDragging = false;
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('touchmove', this.onMouseMove);
        document.removeEventListener('mouseup', this.stopDragging);
        document.removeEventListener('touchend', this.stopDragging);
        this.$emit('update-select-sc-id', this.selectedItem.scId, this.selectedItem.price);
      }
    },
    onMouseMove(event) {
      if (!this.isDragging) return;

      const clientX = event.clientX || event.touches[0].clientX;
      const offsetX = clientX - this.$refs.sliderContainer.getBoundingClientRect().left;
      const percentage = Math.min(Math.max(offsetX / this.$refs.sliderContainer.offsetWidth, 0), 1);

      this.currentValue = Math.round(percentage * this.maxValue);
    },
    closeSuperChatArea() {
      this.$emit('close-superChat');
    },
    getImageUrl(scId) {
      return `${this.$cloudFront}/Image/Public/super_chat_icon/${scId}.png`;
    },
  },
};
</script>

<style lang="scss"></style>
