<template>
  <section class="my-order-details">
    <nav class="breadcrumb">
      <router-link :to="`/${currentLanguage}/member/my-orders`">
        <span>{{ $t('my-orders.key001') }}</span>
      </router-link>
      <span>&nbsp;&gt; {{ $t('my-orders.key003') }}</span>
      <span>{{ orderId }}</span>
    </nav>

    <div class="order-detail">
      <div class="title">{{ $t('my-orders.key009') }}</div>
      <div class="accordion-item" :class="{ active: activeAccordion === 0 }">
        <div class="accordion-heading" @click="toggleAccordion(0)">
          {{ $t('shopping-cart.key009') }}
        </div>
        <div class="accordion-content">
          <section class="section01 products_list" v-for="product in order.packs" :key="product.id">
            <div class="products_item">
              <div class="left">
                <div class="item_img_box">
                  <img :src="product.image" class="item_img" alt="" />
                </div>
                <div class="item_content">
                  <div class="direction">
                    <div class="item_name">
                      {{ product.productName }}
                    </div>
                    <div class="item_dateTime">
                      {{ product.packTitle }}
                    </div>
                    <div class="item_dateTime" v-html="product.time"></div>
                  </div>
                  <div class="item_price">
                    <span class="current_price"> NT$ {{ formatNumber(product.priceUnit) }} </span>
                    <span class="original_price" v-if="product.originalPriceUnit != 0"> NT$ {{ formatNumber(product.originalPriceUnit) }} </span>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="chooseCount">
                  <div class="count">X {{ product.quantity }}</div>
                </div>
              </div>
            </div>
            <div class="product products_item add_item" v-for="(item, addOnIndex) in product.addOnItems" :key="addOnIndex">
              <div class="left">
                <div class="item_img_box">
                  <img :src="item.image" class="product-image" alt="product image" />
                </div>
                <div class="product-info item_content">
                  <div class="direction">
                    <p class="add_announce">{{ $t('shopping-cart.key042') }}</p>
                    <div class="item_name">
                      {{ item.title }}
                    </div>
                    <div class="item_dateTime">
                      {{ item.description }}
                    </div>
                  </div>
                  <div class="item_price">
                    <span class="current_price">NT$ {{ formatNumber(item.priceUnit) }}</span>
                  </div>
                </div>
              </div>

              <div class="right">
                <div class="chooseCount">
                  <div class="count">X {{ item.quantity }}</div>
                </div>
              </div>
            </div>
          </section>
          <div class="divide"></div>
          <section class="summaryCharges">
            <div class="total_amount div_style">
              <span class="label">{{ $t('shopping-cart.key004') }}</span>
              <span class="value">NT$ {{ originalTotalAmount() }}</span>
            </div>
            <div class="promotional_discount div_style">
              <span class="label">{{ $t('shopping-cart.key005') }}</span>
              <div class="value discount_value">-NT$ {{ formatNumber(order.discount) }}</div>
            </div>
            <div class="total_amount div_style">
              <span class="label">{{ $t('shopping-cart.key043') }}</span>
              <span class="value">NT$ {{ order.shipping }}</span>
            </div>
            <div class="totalSubmit">
              <div class="div">
                <span class="label">{{ $t('shopping-cart.key006') }}</span>
                <span class="value">NT$ {{ formatNumber(order.totalAmount) }}</span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div class="order-info">
      <div class="title">{{ $t('my-orders.key010') }}</div>
      <div class="list-box">
        <div class="list-title">{{ $t('my-orders.key003') }}</div>
        <div class="list-content">{{ orderId }}</div>
      </div>
      <div class="list-box">
        <div class="list-title">{{ $t('my-orders.key002') }}</div>
        <div class="list-content">{{ order.createdAt }}</div>
      </div>
      <div class="list-box">
        <div class="list-title">{{ $t('my-orders.key011') }}</div>
        <div class="list-content">{{ order.paymentType }}</div>
      </div>
      <div class="list-box">
        <div class="list-title">{{ $t('my-orders.key012') }}</div>
        <div class="list-content" :class="{ notice: order.statusCode === 'SUCCESS' }">
          {{ order.status }}
        </div>
      </div>
      <div class="list-box">
        <div class="list-title">{{ $t('my-orders.key004') }}</div>
        <div class="list-content">NT$ {{ formatNumber(order.totalAmount) }}</div>
      </div>
      <div class="list-box">
        <div class="list-title">{{ $t('my-orders.key013') }}</div>
        <div class="list-content" v-if="order.invoice">
          {{ order.invoice }}
        </div>
        <div class="list-content darkColor" v-else>{{ $t('my-orders.key014') }}</div>
      </div>
    </div>

    <div class="shipping-info">
      <div class="title">{{ $t('shopping-cart.key011') }}</div>
      <div class="list-box">
        <div class="list-title">{{ $t('shopping-cart.key013') }}</div>
        <div class="list-content">{{ order.name }}</div>
      </div>
      <div class="list-box">
        <div class="list-title">{{ $t('shopping-cart.key014') }}</div>
        <div class="list-content">{{ order.phone }}</div>
      </div>
      <div class="list-box">
        <div class="list-title">E-mail</div>
        <div class="list-content">{{ order.email }}</div>
      </div>
    </div>

    <div class="shipping-info">
      <div class="title">{{ $t('my-orders.key015') }}</div>
      <div class="list-box">
        <div class="list-title">{{ $t('shopping-cart.key024') }}</div>
        <div class="list-content">{{ order.shippingMessage }}</div>
      </div>
      <div class="list-box" v-if="order.shippingDate">
        <div class="list-title">{{ $t('my-orders.key016') }}</div>
        <div class="list-content">{{ order.shippingDate }}</div>
      </div>
      <div class="list-box" v-if="order.shippingCode">
        <div class="list-title">{{ $t('my-orders.key017') }}</div>
        <div class="list-content">{{ order.shippingCode }}</div>
      </div>
      <div class="list-box">
        <div class="list-title">{{ $t('shopping-cart.key025') }}</div>
        <div class="list-content">{{ order.address }}</div>
      </div>
    </div>

    <div class="serial-number" v-if="hasRedeems">
      <div class="title">{{ $t('my-orders.key018') }}</div>
      <div class="accordion-item">
        <div v-for="(product, index) in redeemsInfo.packRedeems" :key="index" class="serial-number-item">
          <div class="products_item">
            <div class="left">
              <div class="item_img_box">
                <img :src="product.image" class="item_img" alt="" />
              </div>
              <div class="item_content">
                <div class="direction">
                  <div class="item_name">
                    {{ product.productName }}
                  </div>
                  <div class="item_dateTime">
                    {{ product.packTitle }}
                  </div>
                  <div class="item_dateTime" v-html="product.time"></div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="chooseCount">
                <div class="count">X {{ product.quantity }}</div>
              </div>
            </div>
          </div>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>{{ $t('my-orders.key019') }}</th>
                  <th>{{ $t('my-orders.key020') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in product.items" :key="index">
                  <td>
                    <div class="flex">
                      {{ item.code }}
                      <div class="copy" @click="copyToClipboard(item.code)">
                        <img src="../../assets/img/member/copy.svg" alt="" />
                      </div>
                    </div>
                  </td>
                  <td :class="{ notice: !item.isUsed }" class="darkColor">
                    {{ getRedeemStatus(item.isUsed) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-for="(product, index) in redeemsInfo.addOnItemRedeems" :key="index" class="serial-number-item">
          <div v-if="product.items.length > 0">
            <div class="products_item">
              <div class="left">
                <div class="item_img_box">
                  <img :src="product.image" class="item_img" alt="" />
                </div>
                <div class="item_content">
                  <div class="direction">
                    <div class="item_name">
                      {{ product.title }}
                    </div>
                    <div class="item_dateTime">
                      {{ product.description }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="right">
                <div class="chooseCount">
                  <div class="count">X {{ product.quantity }}</div>
                </div>
              </div>
            </div>
            <div class="table-container">
              <table>
                <thead>
                  <tr>
                    <th>{{ $t('my-orders.key019') }}</th>
                    <th>{{ $t('my-orders.key020') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in product.items" :key="index">
                    <td>
                      <div class="flex">
                        {{ item.code }}
                        <div class="copy" @click="copyToClipboard(item.code)">
                          <img src="../../assets/img/member/copy.svg" alt="" />
                        </div>
                      </div>
                    </td>
                    <td :class="{ notice: !item.isUsed }" class="darkColor">
                      {{ getRedeemStatus(item.isUsed) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053" target="_blank">
      <div class="button_style_linear">{{ $t('common.contactUs_key001') }}</div>
    </a>
    <div class="copySuccess">{{ $t('member-information.key054') }}</div>
  </section>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { getMyOrderDetails } from '@/api/apiService';
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
export default {
  name: 'ShoppingCart',
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      orderId: this.$route.params.id,
      groupedRedeemInfo: [],
      order: {},
      activeAccordion: null,
    };
  },
  async mounted() {
    await this.getMyOrderDetails();
  },
  computed: {
    ...mapState(['jwt']),
    redeemsInfo() {
      const packRedeems = [];
      const addOnItemRedeems = [];

      const addOnItemMap = {};

      this.order.packs?.forEach((pack) => {
        if (pack.redeems.length > 0) {
          packRedeems.push({
            image: pack.image,
            productName: pack.productName,
            packTitle: pack.packTitle,
            time: pack.time,
            quantity: pack.quantity,
            items: pack.redeems,
          });
        }
        pack.addOnItems?.forEach((item) => {
          if (!addOnItemMap[item.id]) {
            addOnItemMap[item.id] = {
              ...item,
              quantity: 0,
              items: [],
            };
          }

          if (item.redeems.length > 0) {
            addOnItemMap[item.id].quantity += item.quantity;
            addOnItemMap[item.id].items = [...addOnItemMap[item.id].items, ...item.redeems];
          }
        });
      });

      addOnItemRedeems.push(...Object.values(addOnItemMap));

      return {
        packRedeems,
        addOnItemRedeems,
      };
    },
    hasRedeems() {
      return [this.redeemsInfo.packRedeems, this.redeemsInfo.addOnItemRedeems].some((redeems) => redeems.some((redeem) => redeem.items.length > 0));
    },
  },
  methods: {
    async getMyOrderDetails() {
      if (this.jwt) {
        let response;
        try {
          this.loading = true;
          const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;

          response = await getMyOrderDetails(this.orderId, this.jwt, langSetting);
          const responseData = response.data.data;

          this.order = this.transformOrderDetailsData(responseData);
        } catch (error) {
          // TODO: error handling
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
    transformOrderDetailsData(apiData) {
      const transformedData = {
        ...apiData,
        packs: apiData.packs.map((pack) => ({
          ...pack,
          time: this.formatTimeRange(pack.startTime, pack.endTime),
        })),
        createdAt: apiData.createdAt.split('T')[0],
      };

      return transformedData;
    },
    // time format
    formatTimeRange(startTime, endTime) {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);

      const formatDate = (date) => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');

        this.timeZone = '(' + date.toString().match(/\(([^)]+)\)$/)[1] + ')';
        return `${yyyy}/${mm}/${dd} ${hh}:${min} `;
      };

      const formattedStart = formatDate(startDate);
      const formattedEnd = formatDate(endDate);

      return `${formattedStart} ~ ${formattedEnd}`;
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          $('.copySuccess').css('animation-name', 'copySuccess');
          setTimeout(() => {
            $('.copySuccess').css('animation-name', '');
          }, 2000);
        })
        .catch((err) => {
          console.error(this.$t('my-orders.key023'), err);
        });
    },
    getRedeemStatus(isUsed) {
      return isUsed ? this.$t('my-orders.key021') : this.$t('my-orders.key022');
    },
    formatNumber(value) {
      return value != null ? Number(value).toLocaleString('en-US') : '0';
    },
    originalTotalAmount() {
      let orgTotalAmount = this.order.totalAmount + this.order.discount - this.order.shipping;
      return this.formatNumber(orgTotalAmount);
    },
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        this.$nextTick(() => {
          $('.accordion-item').eq(index).find('.accordion-content').slideUp();
        });
        this.activeAccordion = null;
      } else {
        this.$nextTick(() => {
          $('.accordion-item').find('.accordion-content').slideUp();
        });
        this.$nextTick(() => {
          const $currentItem = $('.accordion-item').eq(index).find('.accordion-content');
          $currentItem.slideDown();
        });
        this.activeAccordion = index;
      }
    },
  },
};
</script>

<style lang="scss"></style>
