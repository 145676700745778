<template>
  <div v-if="streamingInfo">
    <!-- 黑色遮罩_Desktop -->
    <div class="mask" v-if="!isMobile" @click="$emit('toggleShowMore')"></div>

    <!-- 詳細說明 -->
    <div class="modal-overlay">
      <div class="modal-content" @click.stop>
        <h2 class="title">{{ streamingInfo.title }}</h2>
        <p class="description">{{ streamingInfo.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramDetails',
  data() {
    return {};
  },
  props: {
    streamingInfo: Object,
  },
  emits: ['toggleShowMore'],
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
