<template>
  <div class="orderComplete">
    <section class="section_flex guest_order">
      <div class="left" v-if="status">
        <h1 class="title">{{ $t('order-complete.key001') }}</h1>
        <dotlottie-player
          id="check-box"
          class="check_icon"
          src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
          background="transparent"
          speed="1"
          autoplay=""
        ></dotlottie-player>
        <p>{{ $t('order-complete.key002') }}</p>
        <p class="heightline">{{ orderId }}</p>
        <p>{{ $t('order-complete.key003') }}</p>
        <p class="heightline">{{ email }}</p>
      </div>
      <div class="left" v-else>
        <h1 class="title">{{ $t('order-complete.key004') }}</h1>
        <img src="../assets/img/public/failed.png" alt="" class="check_icon" />
        <p>{{ $t('order-complete.key005') }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import { removeCartItems, removeCartItemExpiry } from '@/localStorage/cartStorage';

export default {
  name: 'ShoppingCart',
  components: {},
  data() {
    return {
      email: '',
      status: null,
      hasAuth: null,
      orderId: '',
      stage: false,
    };
  },
  mounted() {
    this.status = this.$route.query.status === '1';
    this.email = decodeURIComponent(this.$route.query.email);
    this.hasAuth = this.$route.query.hasAuth === '1';
    this.orderId = decodeURIComponent(this.$route.query.orderId);

    if (this.status) {
      removeCartItems();
      removeCartItemExpiry();
    }
  },
  methods: {
    handleStageUpdate(stageCompleted) {
      this.stage = stageCompleted;
    },
  },
};
</script>

<style lang="scss">
.orderComplete {
  .section_flex {
    display: flex;
  }

  .left {
    width: 100%;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .check_icon {
      width: 60px;
      margin: 30px 0;
      height: auto;
    }

    p {
      margin: 10px 0;
    }

    p.heightline {
      color: #fff599;
    }
  }

  .right {
    width: 35%;
    padding: 60px 30px;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, rgba(77, 77, 77, 0.1), #1e1e1e);

    .pickup {
      width: 80%;
      border: 1px solid #fff;
      border-radius: 10px;
      margin: 20px auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .pickup_img {
        width: 30%;
      }

      p {
        width: 70%;
      }

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .arrow {
      width: 40px;
      display: block;
      margin: 0 auto;
    }

    .click_btn {
      width: 80%;
      text-align: center;
    }
  }

  h1.title {
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1rem;
    margin-right: -1rem;
    margin-bottom: 30px;
    text-align: center;
  }

  h1.contactTitle {
    width: 100%;
    margin-bottom: 0;
    margin-top: 100px;
    border-bottom: 1px solid $primary;
  }
}
@media (max-width: 1200px) {
  .orderComplete .right {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .orderComplete .section_flex {
    flex-direction: column;
  }

  .orderComplete .left,
  .orderComplete .right {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
