<template>
  <div class="sticker-area">
    <div class="sticker-categories">
      <swiper
        :pagination="{ clickable: true }"
        :breakpoints="{
          1200: { slidesPerView: 4.7, spaceBetween: 5 },
          991: { slidesPerView: 4.7, spaceBetween: 5 },
          576: { slidesPerView: 10.5, spaceBetween: 5 },
          0: { slidesPerView: 8.5, spaceBetween: 5 },
        }"
        class="swiper-container"
        ref="swiper"
      >
        <swiper-slide
          v-for="(sticker, index) in stickersList"
          :key="index"
          class="category"
          @click="selectCategory(sticker.id)"
          :class="{ active: sticker.id === selectedStickerId }"
        >
          <img :src="sticker.cover" alt="" />
        </swiper-slide>
        <swiper-slide> </swiper-slide>
      </swiper>
    </div>
    <div class="sticker-content">
      <div class="sticker" v-for="sticker in selectedStickerIndexes" :key="sticker.src" @click="onStickerClick(sticker)">
        <img :src="sticker.src" :alt="sticker.alt" class="sticker-image" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'StickerArea',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    stickers: Array,
    default: () => [],
  },
  data() {
    return {
      stickersList: [],
      selectedStickerId: null,
      selectedStickerIndexes: [],
    };
  },
  computed: {},
  mounted() {
    this.generateStickersList();
  },
  methods: {
    generateStickersList() {
      this.stickersList = this.stickers.map((sticker) => ({
        id: sticker.emojiId,
        cover: this.generateStickerUrl(sticker.emojiId, 'miniature'),
      }));

      this.selectedStickerId = this.stickersList[0].id;
      this.selectCategory(this.stickersList[0].id);
    },
    selectCategory(stickerId) {
      this.selectedStickerId = stickerId;
      const selectedSticker = this.stickers.find((sticker) => sticker.emojiId === stickerId);

      if (selectedSticker) {
        this.selectedStickerIndexes = Array.from({ length: selectedSticker.stickerCount }, (_, index) => ({
          src: this.generateStickerUrl(selectedSticker.emojiId, index),
          alt: `${selectedSticker.emojiId}_${index}`,
        }));
      } else {
        this.selectedStickerIndexes = [];
      }
    },
    generateStickerUrl(id, index) {
      return `${this.$cloudFront}/Image/Public/chat_stickers/${id}/${index}.png`;
    },
    onStickerClick(sticker) {
      this.$emit('sticker-selected', sticker);
    },
  },
};
</script>

<style lang="scss"></style>
