<template>
  <section class="wrapper download_page faq">
    <h1>{{ $t('faq.key002') }}</h1>
    <div class="block">
      <div class="faq-item" v-for="(faq, index) in faqs_download" :key="'download_' + index">
        <div class="faq-question" @click="toggleAnswer('download_' + index)" :style="{ color: faq.open ? 'white' : '#A6BED9' }">
          <i class="bi bi-caret-right-fill faq-icon" :class="{ open: faq.open }"></i>
          {{ faq.question }}
        </div>
        <div class="faq-answer" v-if="faq.open" v-html="faq.answer"></div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'AccountIssues',
  components: {},
  mounted() {
    this.getAnswerContent();
  },

  data() {
    return {
      formatAnswer: '',
      faqs_download: [
        {
          question: this.$t('account-issues.key001'),
          answer: `
                    ${this.$t('account-issues.key002')}
                    <ul>
                        <li>${this.$t('account-issues.key004')}</li>
                        <li>${this.$t('account-issues.key005')}</li>
                        <li>${this.$t('account-issues.key006')}</li>
                    </ul>
                    `,
          open: false,
        },
        {
          question: this.$t('account-issues.key007'),
          answer: this.$t('account-issues.key008'),
          open: false,
        },
        {
          question: this.$t('account-issues.key009'),
          answer: `
                    <ul>
                        <li>${this.$t('account-issues.key010')}</li>
                        <li>${this.$t('account-issues.key011')}</li>
                        <li>${this.$t('account-issues.key012')}</li>
                    </ul>`,
          open: false,
        },
        {
          question: this.$t('account-issues.key013'),
          answer: this.getAnswerContent(),
          open: false,
        },
        {
          question: this.$t('account-issues.key017'),
          answer: this.$t('account-issues.key018'),
          open: false,
        },
        {
          question: this.$t('account-issues.key021'),
          answer: this.getPrivacyLink(),
          open: false,
        },
      ],
    };
  },
  props: {},
  computed: {},
  methods: {
    toggleAnswer: function (key) {
      this.faqs_download.forEach((faq, index) => {
        if ('download_' + index === key) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
      });
    },
    getPrivacyLink() {
      const links = {
        zh_tw: 'https://www.htc.com/tw/terms/privacy/',
        en: 'https://www.htc.com/us/terms/privacy/',
        ja: 'https://www.htc.com/jp/terms/privacy/ ',
      };
      const lang = this.$route.params.lang;
      console.log(lang);

      const privacyUrl = links[lang] || links.en;

      return this.$t('account-issues.key022', {
        HTCPrivacy: `<a href="${privacyUrl}" target="_blank" rel="noopener noreferrer">
        ${this.$t('privacy.key003')}
      </a>`,
      });
    },
    getAnswerContent() {
      this.formatAnswer = this.$t('account-issues.key014', {
        pswResetUrl: `
        <a href="${this.$t('account-issues.key016')}">
        ${this.$t('account-issues.key015')}
        </a>`,
      });
      return this.formatAnswer;
    },
  },
};
</script>

<style lang="scss"></style>
