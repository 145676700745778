<template>
  <div class="alert-window" v-if="isVisible">
    <div class="alert-content">
      <div class="alert-content-box">
        <h3>{{ title }}</h3>
        <p v-html="message"></p>
      </div>
      <div class="btn">
        <button @click="handleOk">{{ $t('common.alert_key001') }}</button>
        <button v-if="type === 'confirm'" @click="handleCancel">{{ $t('common.alert_key002') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: 'Your message here.' },
    // okButtonText: { type: String, default: 'Ok' },
    // cancelButtonText: { type: String, default: 'Cancel' },
    isVisible: { type: Boolean, default: true },
    type: { type: String, default: 'alert' },
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  methods: {
    handleOk() {
      this.$emit('confirm');
      document.body.style.overflow = '';
    },
    handleCancel() {
      this.$emit('cancel');
      document.body.style.overflow = '';
    },
  },
};
</script>

<style scoped>
.alert-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.8);
  z-index: 1400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert-content {
  min-width: 300px;
  max-width: 400px;
  background: rgba(25, 25, 25, 0.8);
  border: 0.25px solid hsla(0, 0%, 100%, 0.6);
  box-shadow: 0 0 4px 0 rgba(103, 55, 140, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  text-align: center;
  @media (max-width: 576px) {
    max-width: 90%;
  }
}
.alert-content-box {
  padding: 20px 30px;
}
.alert-content h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.alert-content-box p {
  white-space: pre-line;
}
.alert-content .btn {
  display: flex;
}
.alert-content-box .a-link {
  color: #e79fff;
  text-decoration: underline;
}
.alert-window button {
  flex-basis: 50%;
  background: rgba(103, 55, 140, 0.2);
  border-top: 0.25px solid hsla(0, 0%, 100%, 0.6);
  margin-top: 20px;
  padding: 10px 20px;

  &:first-child {
    border-right: 0.25px solid hsla(0, 0%, 100%, 0.6);
  }
}
.alert-window button:hover {
  background: rgba(103, 55, 140, 0.5);
}
</style>
