import axios from './axios';

const baseURL = process.env.VUE_APP_LAMBDA_URL;

//webStatus
const webStatus = () => {
  try {
    return axios.get(`${baseURL}/webStatus`, {
      headers: {
        'X-API-Key': process.env.VUE_APP_WEBSTATUS_API_KEY,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export { webStatus };
