<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-content">
      <button class="close-button" @click="closeModal"><i class="bi bi-x"></i>{{ $t('streamingVideo.key030') }}</button>

      <div class="modal-message">
        <p @click="openInNewTab" class="pay_btn">🔗 {{ $t('streamingVideo.key028') }}</p>
        <p>{{ $t('streamingVideo.key029') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-useless-escape */
import { computed, defineProps, defineEmits, watch } from 'vue';

// 定義組件接收的 props
const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: false,
  },
  formAction: {
    type: String,
    required: true,
  },
  formData: {
    type: Object,
    required: true,
  },
});

// 定義事件
const emit = defineEmits(['update:visible']);

// 關閉模態函數
const closeModal = () => {
  emit('update:visible', false);
  if (typeof props.onClose === 'function') {
    props.onClose();
  }
};

const visible = computed(() => props.visible);

watch(visible, (newVal) => {
  if (newVal) {
    openInNewTab();
  }
});

// 在新分頁中提交表單
const openInNewTab = () => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = props.formAction;
  form.target = '_blank';

  Object.entries(props.formData).forEach(([key, value]) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
</script>

<style scoped>
.pay_btn {
  color: #000;
  background-color: #e79fff;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7777;
}

.modal-content {
  width: 400px;
  height: 200px;
  background-color: #fff;
  border: 1px solid #fff;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(16, 16, 16, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

@media (max-width: 991px) {
  .modal-content {
    width: 95%;
    min-height: 200px;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: 400;
  background: #ff5f5f;
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}
.close-button:hover {
  background: rgb(230, 76, 76);
}

.modal-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #333;
}
.modal-message p {
  margin: 5px 0;
}
</style>
