<template>
  <div style="position: relative; width: 100%; height: 100%">
    <video style="position: relative; width: 100%; height: 100%" ref="videoElement" class="video-js" controls autoplay playsinline></video>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, defineProps, defineExpose, watch, defineEmits, computed, toRaw } from 'vue';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { PlayerState } from './constants/videoPlayer.js';
const emit = defineEmits(['player-error', 'player-start', 'player-ended', 'player-unexpectedly-ended']);
import { debounce } from 'lodash';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  // Control the timing for changing the video source.
  // Set to false if the video source does not need to be changed.
  // Set to true when a source change is required, and revert to false after the video starts playing normally
  // to prevent the video from flickering because video.js restarts playback when the src is loaded.
  forceReloadWhenSrcChange: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: '100%',
  },
});

const videoElement = ref(null);

const player = ref(null);

const playerState = ref(PlayerState.Created);
const isPlayerInitialized = ref(false);

const IvsPlayerInstance = computed(() => {
  return player.value?.getIVSPlayer();
});

// Workaround
// when timeupdate, call this function, if not called after 10 seconds, check if the video is playing
// if state is playing but not timeupdate, then the video is stopped unexpectedly
// set it to ended
const debounceCheckVideoNotStoppedButStatusIsPlaying = debounce(() => {
  if (playerState.value !== PlayerState.Playing) {
    return;
  }

  player.value.pause();
  playerState.value = PlayerState.Ended;
  emit('player-unexpectedly-ended');
}, 10000);

const initPlayer = () => {
  player.value = videojs(
    videoElement.value,
    {
      techOrder: ['AmazonIVS'],
    },
    () => {
      player.value.on('error', (error) => {
        emitError(error);
      });

      player.value.on('timeupdate', () => {
        debounceCheckVideoNotStoppedButStatusIsPlaying();
      });

      toRaw(player.value).enableIVSQualityPlugin();

      const playerEvent = player.value.getIVSEvents().PlayerEventType;
      const ivsPlayerState = player.value.getIVSEvents().PlayerState;

      if (IvsPlayerInstance.value) {
        IvsPlayerInstance.value.addEventListener(playerEvent.ERROR, emitError);

        IvsPlayerInstance.value.addEventListener(ivsPlayerState.ENDED, onVideoEnded);
        IvsPlayerInstance.value.addEventListener(ivsPlayerState.PLAYING, onStartPlayer);
      }

      playerState.value = PlayerState.Init;
    },
  );
};

const emitError = (error) => {
  playerState.value = PlayerState.Error;
  emit('player-error', error);
};

const changeSrc = () => {
  if (player?.value && typeof player.value.src === 'function') {
    player.value.src(props.src);
  }
};

const disposePlayer = () => {
  player.value?.dispose();
};

onMounted(() => {
  try {
    initPlayer();
  } catch (error) {
    playerState.value = PlayerState.Error;
    emitError(error);
  }
});

onUnmounted(() => {
  try {
    const playerEvent = player.value.getIVSEvents().PlayerEventType;
    const ivsPlayerState = player.value.getIVSEvents().PlayerState;

    IvsPlayerInstance.value.removeEventListener(playerEvent.ERROR, emitError);

    IvsPlayerInstance.value.removeEventListener(ivsPlayerState.ENDED, onVideoEnded);
    IvsPlayerInstance.value.removeEventListener(ivsPlayerState.PLAYING, onStartPlayer);

    disposePlayer();
  } catch (error) {
    emitError(error);
  }
});

const onVideoEnded = () => {
  playerState.value = PlayerState.Ended;
  emit('player-ended');
};

const onStartPlayer = () => {
  playerState.value = PlayerState.Playing;
  emit('player-start');
};

watch(
  () => props.src,
  () => {
    try {
      if (props.forceReloadWhenSrcChange || !isPlayerInitialized.value) {
        if (!isPlayerInitialized.value) {
          isPlayerInitialized.value = true;
        }

        changeSrc();
      }
    } catch (error) {
      emitError(error);
    }
  },
);

const playVideo = () => {
  if (player.value) {
    // workaround for reload video
    player.value.src('');

    player.value.src(props.src);
  }
};

defineExpose({
  playVideo,
  playerState,
});
</script>

<style scoped></style>
