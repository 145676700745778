import axios from './axios';
import store from '../vuex';

const getStreamingInfo = (cid, currentLanguage) => {
  return axios.get(`/api/streaming/${cid}`, {
    headers: {
      'Accept-Language': currentLanguage,
    },
  });
};

const getVideoPlayUrl = (cid, isSrcNeedAuth) => {
  if (isSrcNeedAuth) {
    return axios.get(`/api/streaming/${cid}/authedPlayUrl`, {
      headers: {
        Authorization: `Bearer ${store.state.jwt}`,
      },
    });
  } else {
    return axios.get(`/api/streaming/${cid}/playUrl`);
  }
};

const streamingGetChat = (cid, ts) => {
  return axios.get(`/api/streaming/${cid}/chat`, {
    params: { ts },
  });
};

const streamingGetSCMsg = (cid) => {
  return axios.get(`/api/streaming/${cid}/sc`, {});
};

const streamingPostMsg = (cid, data, jwt) => {
  return axios.post(
    `/api/streaming/${cid}/chat`,
    {
      msg: data.msg,
      sc: data.sc,
    },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
};

const getSCInfo = (currentLanguage) => {
  return axios.get('/api/system/scInfo', {
    headers: {
      'Accept-Language': currentLanguage,
    },
  });
};

const getUserItems = (jwt) => {
  return axios.get('/api/user/items', {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

const checkPCLoginStatus = () => {
  return axios.get('/api/user/checkPCLoginStatus', {
    headers: {
      Authorization: `Bearer ${store.state.jwt}`,
    },
  });
};

const kickPCUser = () => {
  return axios.post('/api/user/kickPCUser', {}, {
    headers: {
      Authorization: `Bearer ${store.state.jwt}`,
    },
  });
};

export { streamingGetChat, streamingGetSCMsg, streamingPostMsg, getSCInfo, getUserItems, getStreamingInfo, getVideoPlayUrl, kickPCUser, checkPCLoginStatus };
