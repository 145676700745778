<template>
  <div :player-state="playerState">
    <video-player
      ref="videoPlayerRef"
      class="video-player"
      :src="videoSrc"
      @player-error="handlePlayerError"
      @player-start="handlePlayerStart"
      @player-ended="handlePlayerEnded"
      @player-unexpectedly-ended="handlePlayerUnexpectedlyEnded"
      :force-reload-when-src-change="forceReloadWhenSrcChange"
    />
    <div class="overlay" v-if="showOverLay">
      <div class="content-box" v-if="!isStreamStarted">
        <img :src="startingSoonImagePath" alt="starting soon" />
      </div>
      <div class="content-box" v-else-if="isStreamEnded">
        <img :src="endedImagePath" alt="ended" />
      </div>
      <div class="content-box" v-else>
        <i class="bi bi-exclamation-circle"></i>
        <div class="content-text">
          <p v-if="!isInternetConnected">{{ $t('streamingVideo.key018') }}</p>
          <p v-else-if="retryFetchVideoInterval !== null">{{ $t('streamingVideo.key019') }}</p>
          <p v-else>{{ $t('streamingVideo.key020') }}</p>
          <p v-if="retryFetchVideoInterval !== null" class="connection-loading">{{ $t('streamingVideo.key021') }}</p>
          <button v-else class="btn-reconnect" @click="handleReconnect">
            <i class="bi bi-arrow-counterclockwise"></i>
            {{ $t('streamingVideo.key022') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onBeforeUnmount, onMounted, computed, inject, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { getVideoPlayUrl } from '@/api/streamingService.js';
import { HttpStatusCode } from 'axios';
import { PlayerState } from '@/components/constants/videoPlayer.js';
import startingSoonImage from '@/assets/img/streaming/streaming_default_starting_soon.jpg';
import endedImage from '@/assets/img/streaming/streaming_default_ended.jpg';

// 12 * 5 = 1 minutes
const MAX_RETRY_TIMES = 12;

const RETRY_FETCH_VIDEO_INTERVAL = 5000;
const NOT_STARTED_RETRY_FETCH_VIDEO_INTERVAL = 10000;

const store = useStore();
const router = useRouter();

const handleCompetedLogin = inject('handleCompetedLogin');

const videoSrc = ref('');
const videoPlayerRef = ref(null);
// first time need change src
const forceReloadWhenSrcChange = ref(true);
const isFetchingVideoSrc = ref(false);
const retryFetchVideoInterval = ref(null);
const retryTimes = ref(0);

const isUnmounted = ref(false);
const isInternetConnected = ref(true);

const emit = defineEmits(['refresh-streaming-info']);

const props = defineProps({
  /**
   * @description 節目資訊
   * @property {string} cid - 節目ID
   * @property {boolean} needLogin - 是否需要登入
   * @property {string} startTime - 節目開始時間
   * @property {string} endTime - 節目結束時間
   */
  streamingInfo: {
    type: Object,
    required: true,
  },
});

const playerState = computed(() => videoPlayerRef.value?.playerState);

const startingSoonImagePath = computed(() => startingSoonImage);
const endedImagePath = computed(() => endedImage);

const isStreamStarted = computed(() => new Date(props.streamingInfo.startTime) <= new Date());
const isStreamEnded = computed(() => new Date(props.streamingInfo.endTime) <= new Date());

const showOverLay = computed(
  () => playerState.value !== PlayerState.Playing && ((playerState.value === PlayerState.Error && isStreamStarted.value) || !isStreamStarted.value || isStreamEnded.value),
);

onMounted(async () => {
  try {
    await fetchVideoSrc();
  } catch (error) {
    console.error(error);
  }
});

onBeforeUnmount(() => {
  isUnmounted.value = true;

  removeRetryFetchVideoInterval();
});

const logOut = () => {
  store.dispatch('logout');

  router.push({
    path: `/${store.state.currentLanguage}/member-login/login`,
  });
};

const handlePlayerError = async (error) => {
  console.error(error);

  if (error?.type === 'ErrorAuthorization') {
    logOut();
  } else if (error?.type !== 'error') {
    if (isStreamStarted.value) {
      setRetryFetchVideoInterval();
    } else {
      notStartedRetryFetchVideoInterval();
    }
  } else {
    console.error('error');
  }
};

const fetchVideoSrc = async () => {
  try {
    if (isFetchingVideoSrc.value || isUnmounted.value) {
      return;
    }

    isFetchingVideoSrc.value = true;

    if (props.streamingInfo.needLogin && !store.state.jwt) {
      return;
    }

    const result = await getVideoPlayUrl(props.streamingInfo.cid, props.streamingInfo.needLogin);
    const playUrl = result.data.data;

    // get playUrl before m3u8
    const newPlayUrlBeforeM3u8 = playUrl.split('.m3u8')[0];
    const oldPlayUrlBeforeM3u8 = videoSrc.value.split('.m3u8')[0];

    // video src origin changed
    if (newPlayUrlBeforeM3u8 !== oldPlayUrlBeforeM3u8) {
      forceReloadWhenSrcChange.value = true;
    }

    if (forceReloadWhenSrcChange.value) {
      // if url is the same, play video directly
      if (videoSrc.value === playUrl) {
        videoPlayerRef.value?.playVideo();
      } else {
        videoSrc.value = playUrl;
      }
    }
  } catch (error) {
    console.error(error);

    if (error?.response?.status === HttpStatusCode.Unauthorized && typeof handleCompetedLogin === 'function') {
      handleCompetedLogin();
    }
    // first time fetch error, retry fetch video src
    setRetryFetchVideoInterval();
  } finally {
    isFetchingVideoSrc.value = false;
  }
};

// when stream not started, retry fetch video src every 10 seconds
const notStartedRetryFetchVideoInterval = () => {
  if (!retryFetchVideoInterval.value) {
    retryFetchVideoInterval.value = setInterval(() => {
      retryFetchVideoSrc(false);
    }, NOT_STARTED_RETRY_FETCH_VIDEO_INTERVAL);
  }

  forceReloadWhenSrcChange.value = true;
};

const setRetryFetchVideoInterval = () => {
  if (!retryFetchVideoInterval.value) {
    retryFetchVideoInterval.value = setInterval(retryFetchVideoSrc, RETRY_FETCH_VIDEO_INTERVAL);
  }

  forceReloadWhenSrcChange.value = true;
};

const removeRetryFetchVideoInterval = () => {
  if (retryFetchVideoInterval.value) {
    clearInterval(retryFetchVideoInterval.value);
    retryFetchVideoInterval.value = null;
  }

  retryTimes.value = 0;
};

const retryFetchVideoSrc = async (limit = true) => {
  if (retryTimes.value >= MAX_RETRY_TIMES) {
    removeRetryFetchVideoInterval();
    return;
  }

  await checkInternet();

  if (limit) {
    retryTimes.value++;
  }

  fetchVideoSrc();
};

const checkInternet = async () => {
  try {
    await fetch(`https://www.google.com/favicon.ico?_=${Date.now()}`, { mode: 'no-cors', cache: 'no-store' });
    isInternetConnected.value = true;
    return true;
  } catch (error) {
    isInternetConnected.value = false;
    return false;
  }
};

const handlePlayerStart = () => {
  removeRetryFetchVideoInterval();
  isInternetConnected.value = true;
  // if video is playing normally, don't need to change src
  forceReloadWhenSrcChange.value = false;
};

// when video ended, try to reload video src because the video might be unexpectedly interrupted
const handlePlayerEnded = () => {
  setRetryFetchVideoInterval();
  emit('refresh-streaming-info');
};

const handlePlayerUnexpectedlyEnded = () => {
  emit('refresh-streaming-info');
  if (isStreamEnded.value) {
    setRetryFetchVideoInterval();
  } else {
    if (!retryFetchVideoInterval.value) {
      retryFetchVideoInterval.value = setInterval(() => {
        retryFetchVideoSrc(false);
      }, RETRY_FETCH_VIDEO_INTERVAL);
    }

    forceReloadWhenSrcChange.value = true;
  }
};

const handleReconnect = () => {
  setRetryFetchVideoInterval();
};
</script>

<style lang="scss"></style>
