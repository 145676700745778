<template>
  <div class="chat-container">
    <i class="bi bi-x leave-icon colseChatRoom-Mobile" @click="toggleShowChatRoom" v-show="!showSuperChatArea && !showSticker && isMobile"></i>
    <ChatMessage @toggle-showChatRoom="toggleShowChatRoom" ref="chatMessageComponent" />
    <div class="user-input">
      <div class="super-chat-area">
        <div v-if="!showSuperChatArea" class="super-chat-btn" @click="toggleSuperChat">
          <div class="aria-label" :aria-label="$t('streamingVideo.key004')">
            <img src="@/assets/img/member/program/super_chat.svg" :alt="$t('streamingVideo.key004')" class="chat-input-btn" />
          </div>
          <p>{{ $t('streamingVideo.key004') }}</p>
        </div>
        <SuperChatArea v-else @close-superChat="toggleSuperChat" @update-select-sc-id="updateSelectedSCId" ref="superChatArea" :scs="scs" />
      </div>
      <div class="sticker-area" v-show="showSticker">
        <StickerArea v-if="stickers.length" :stickers="stickers" @sticker-selected="addStickerToInput" />
      </div>

      <div class="chat-input">
        <div class="input-container">
          <div
            contenteditable="true"
            ref="messageInput"
            :class="['message-input', { 'super-chat-open': showSuperChatArea }]"
            @input="updateMessageInput"
            @copy="handleCopy"
            @paste="handlePaste"
            @keydown.enter.prevent="handleEnter"
            @compositionstart="isComposing = true"
            @compositionend="isComposing = false"
          ></div>
          <div class="chat-input-bar-button">
            <div class="aria-label" :aria-label="$t('streamingVideo.key003')" @click="toggleSticker" v-show="isMobile">
              <img src="@/assets/img/streaming/streaming_StickerBtn_all_50x50.svg" :alt="$t('streamingVideo.key003')" class="chat-input-btn" />
            </div>
            <p class="remaining-chars" :class="{ exceeded: remainingChars < 0 }" v-show="remainingChars < 150">
              {{ remainingChars }}
            </p>
          </div>
        </div>
        <div class="msg-input-nav-bar" v-if="!showSuperChatArea">
          <div class="chat-input-bar-button">
            <div class="aria-label" :aria-label="$t('streamingVideo.key003')" @click="toggleSticker" v-show="!isMobile">
              <img src="@/assets/img/streaming/streaming_StickerBtn_all_50x50.svg" :alt="$t('streamingVideo.key003')" class="chat-input-btn" />
            </div>
            <div
              class="send-btn aria-label"
              :class="{ 'send-btn-disable': remainingChars < 0 || remainingChars === 200 }"
              :aria-label="$t('streamingVideo.key002')"
              @click="sendMessage()"
            >
              <img src="@/assets/img/streaming/streaming_sandMsgD_all_50x50.svg" :alt="$t('streamingVideo.key002')" class="chat-input-btn" />
            </div>
          </div>
        </div>
        <div class="msg-input-nav-bar" v-else>
          <div class="chat-input-bar-button" v-show="!isMobile">
            <div class="aria-label" :aria-label="$t('streamingVideo.key003')" @click="toggleSticker">
              <img src="@/assets/img/streaming/streaming_StickerBtn_all_50x50.svg" :alt="$t('streamingVideo.key003')" class="chat-input-btn" />
            </div>
          </div>
        </div>
      </div>
      <div class="input-alert">
        <p>{{ inputAlert }}</p>
      </div>
      <div class="super-chat-sand-btn" :class="{ 'send-btn-disable': remainingChars < 0 || remainingChars === 200 }" v-if="showSuperChatArea" @click="sendMessage(true)">
        <p>{{ SCbuttonText }}</p>
        <img src="@/assets/img/member/program/send_super_chat.svg" alt="" class="chat-input-btn" />
      </div>
    </div>
    <PurchaseModal :visible="showPurchaseModal" :onClose="closePurchaseModal" :formAction="purchaseFormAction" :formData="purchaseFormData" />
    <Loading v-show="isLoading" />
  </div>
</template>

<script>
import { defineComponent, inject } from 'vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import ChatMessage from '@/pages/programVideo/chatRoom/ChatMessage.vue';
import StickerArea from '@/pages/programVideo/chatRoom/Sticker.vue';
import SuperChatArea from '@/pages/programVideo/chatRoom/SuperChat.vue';
import forbiddenWords from '@/assets/data/forbiddenWords.json';
import { streamingPostMsg } from '@/api/streamingService.js';
import PurchaseModal from '@/components/PurchaseModal.vue';
import Loading from '@/components/Loading.vue';
import { HttpStatusCode } from 'axios';
import { getUserInfo } from '@/api/apiService';
import { createAuthOrder } from '@/api/cartService';

// TODO: get list from server
const SC_PURCHASE_INFO = {
  productId: '7be5bbb9-5038-467e-88ba-9fe335a6c98d',
  planId: '5cac0dac-e0aa-45c8-a084-1a45142765e4',
  IPR9001: { sessionId: 'e650a201-d9c0-4aa6-b5f8-bb5d1f168034', packId: 'f303deb8-7b5e-4c99-a57d-3ecf8e221efc' },
  IPR9002: { sessionId: 'e650a201-d9c0-4aa6-b5f8-bb5d1f168034', packId: '3f149bd2-9d69-4e60-8c33-6ae70a56e7a9' },
  IPR9003: { sessionId: 'e650a201-d9c0-4aa6-b5f8-bb5d1f168034', packId: '43aed635-5423-446c-a3fd-b365decca9fd' },
  IPR9004: { sessionId: 'e650a201-d9c0-4aa6-b5f8-bb5d1f168034', packId: '9fcfa0d8-efd9-41ea-bf98-cdd90ce9ff27' },
  IPR9005: { sessionId: 'e650a201-d9c0-4aa6-b5f8-bb5d1f168034', packId: 'afb21394-57c1-4f48-88f3-af1ecab795e0' },
  IPR9006: { sessionId: 'e650a201-d9c0-4aa6-b5f8-bb5d1f168034', packId: '3f63fab3-7628-453a-805c-08f45dc42cab' },
  IPR9007: { sessionId: 'e650a201-d9c0-4aa6-b5f8-bb5d1f168034', packId: 'd2242429-1b2f-4c8a-8e1f-d7a29e3d38f3' },
  IPR9008: { sessionId: 'e650a201-d9c0-4aa6-b5f8-bb5d1f168034', packId: '32f82991-8e50-45f6-819f-0e070f4a86ed' },
};

export default defineComponent({
  name: 'ChatRoom',
  emits: ['toggle-showLoginModal', 'toggle-showChatRoom', 'getUserItems'],
  props: {
    scs: {
      type: Array,
      default: () => [],
    },
    stickers: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    StickerArea,
    SuperChatArea,
    ChatMessage,
    PurchaseModal,
    Loading,
  },
  setup() {
    const handleCompetedLogin = inject('handleCompetedLogin');
    const messageInput = ref(null);

    const handleOutsideClick = (event) => {
      if (messageInput.value && !messageInput.value.contains(event.target)) {
        messageInput.value.blur();
      }
    };
    const handlePaste = (event) => {
      event.preventDefault();
      const plainText = event.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, plainText);
    };

    onMounted(() => {
      document.addEventListener('click', handleOutsideClick);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleOutsideClick);
    });

    return {
      handleOutsideClick,
      handlePaste,
      handleCompetedLogin,
    };
  },
  data() {
    return {
      showSticker: false,
      showSuperChatArea: false,
      showLoginModal: true,
      messageInput: '',
      inputAlert: '',
      forbiddenWords: forbiddenWords,
      maxChars: 200,
      countChar: 0,
      selectSCId: null,
      selectSCPrice: null,
      isSending: false,
      isComposing: false,
      isLoading: false,
      showPurchaseModal: false,
      purchaseFormAction: process.env.NEWEB_PAY_URL,
      purchaseFormData: {
        MerchantID: '',
        Version: '',
        TradeInfo: '',
        TradeSha: '',
      },
      cid: this.$route.params.cid,
    };
  },
  computed: {
    remainingChars() {
      // Calculate sticker characters first
      const imgCount = (this.messageInput.match(/<img\b[^>]*>/gi) || []).length;
      // HTML Decoding
      const decodedMessage = this.decodeHtml(this.messageInput);
      const textLength = decodedMessage.replace(/<img\b[^>]*>/gi, '').length;
      const totalLength = textLength + imgCount * 4;
      return this.maxChars - totalLength;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    jwt() {
      return this.$store.state.jwt;
    },
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },
    SCbuttonText() {
      const selectedSC = this.scs.find((sc) => sc.id === this.selectSCId);
      return selectedSC && selectedSC.qty > 0 ? this.$t('streamingVideo.key006') : this.$t('streamingVideo.key027');
    },
  },
  mounted() {},
  methods: {
    handleEnter(event) {
      if (this.showSuperChatArea || this.isComposing) {
        return;
      }
      event.preventDefault();
      this.sendMessage();
    },
    async closePurchaseModal() {
      this.showPurchaseModal = false;
      try {
        this.isLoading = true;
        this.$emit('getUserItems');

        const selectedSC = this.scs.find((sc) => sc.id === this.selectSCId);
        if (!selectedSC || selectedSC.qty <= 0) {
          return;
        }

        this.$gtm.push({
          event: 'sc_purchase_complete',
          sc_amount: this.selectSCPrice,
          device_type: navigator.userAgent,
          custom_page_path: this.cid,
          transaction_type: 'SuperChat',
          value: this.selectSCPrice,
        });

        this.sendMessage(true);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async startPurchaseSc() {
      try {
        if (!this.jwt) {
          return;
        }
        this.isLoading = true;
        const userInfo = await getUserInfo(this.jwt);

        // create order
        const scPurchaseInfo = SC_PURCHASE_INFO[this.selectSCId];
        if (!scPurchaseInfo) {
          return;
        }

        const createOrderData = {
          checkProduct: false,
          orderItems: [
            {
              sessionId: scPurchaseInfo.sessionId,
              packId: scPurchaseInfo.packId,
              quantity: 1,
            },
          ],
          name: userInfo.name || '未填寫',
          phone: userInfo.phone,
          email: userInfo.contactEmail,
          address: userInfo.address,
          language: this.currentLanguage,
          shipping: 0,
          payment: ['CREDIT'],
          orderSource: 'Streaming',
        };

        const langSetting = this.currentLanguage === 'zh_tw' ? 'zh-tw' : this.currentLanguage;
        const response = await createAuthOrder(createOrderData, langSetting, this.jwt);

        if (response.data?.data) {
          this.purchaseFormData.MerchantID = response.data.data.merchantId;
          this.purchaseFormData.Version = response.data.data.version;
          this.purchaseFormData.TradeInfo = response.data.data.tradeInfo;
          this.purchaseFormData.TradeSha = response.data.data.tradeSha;

          this.showPurchaseModal = true;
        }
      } catch (error) {
        console.error('Error fetching start purchase SC:', error);
      } finally {
        this.isLoading = false;
      }
    },
    decodeHtml(input) {
      const parser = new DOMParser();
      const decodedString = parser.parseFromString(input, 'text/html').documentElement.textContent;
      return decodedString;
    },
    // sticker
    addStickerToInput(sticker) {
      const imgHtml = `<img src="${sticker.src}" alt="${sticker.alt}" class="input-sticker" />`;
      this.$refs.messageInput.innerHTML += imgHtml;
      this.updateMessageInput();
    },
    // message
    updateMessageInput() {
      this.messageInput = this.$refs.messageInput.innerHTML;
      // Exceeding -100: Remove excess characters or stickers.
      while (this.remainingChars < -100) {
        const lastImg = this.$refs.messageInput.querySelector('img:last-of-type');
        if (lastImg) {
          lastImg.remove();
        } else {
          this.messageInput = this.messageInput.slice(0, -1);
          this.$refs.messageInput.innerHTML = this.messageInput;
        }
      }

      const messageInputElement = this.$refs.messageInput;
      messageInputElement.scrollTop = messageInputElement.scrollHeight;
    },
    //Send Msg
    async sendMessage(SCSend = false) {
      if (SCSend && (this.remainingChars < 0 || this.remainingChars >= 200)) {
        this.$gtm.push({
          event: 'sc_inputEmpty_click',
          sc_amount: this.selectSCPrice,
          device_type: navigator.userAgent,
          custom_page_path: this.cid,
        });
        return;
      }

      if (!this.jwt) {
        this.$emit('toggle-showLoginModal');
        return;
      }
      if (this.isSending) return;
      this.isSending = true;
      let transformedMessage = this.transformMessageInput();

      // Check if the message contains only spaces and prevent sending
      if (/^\s*$/.test(transformedMessage)) {
        this.isSending = false;
        return;
      }
      // Remove trailing spaces from the message before sending
      transformedMessage = transformedMessage.trimEnd();

      if (!this.isWordingValid(transformedMessage)) {
        this.inputAlert = this.$t('member-information.key068');
        this.isSending = false;
        return;
      } else if (SCSend) {
        const selectedSC = this.scs.find((sc) => sc.id === this.selectSCId);
        if (!selectedSC || selectedSC.qty <= 0) {
          this.startPurchaseSc();
          this.isSending = false;
          this.$gtm.push({
            event: 'sc_purchase_attempt',
            sc_amount: this.selectSCPrice,
            device_type: navigator.userAgent,
            custom_page_path: this.cid,
            transaction_type: 'SuperChat',
            value: this.selectSCPrice,
          });
          return;
        }
      }

      this.inputAlert = '';

      const data = {
        msg: transformedMessage,
        sc: SCSend ? this.selectSCId || '' : '',
      };
      const cid = this.$route.params.cid;

      try {
        await streamingPostMsg(cid, data, this.jwt);
        this.showSticker = false;
        this.showSuperChatArea = false;
        this.$refs.messageInput.innerHTML = '';
        this.messageInput = '';
        this.$refs.chatMessageComponent.resetChatUpdates();
        this.$refs.chatMessageComponent.scrollToBottom();

        if (SCSend) {
          this.$gtm.push({
            event: 'sc_send',
            sc_amount: this.selectSCPrice,
            device_type: navigator.userAgent,
            custom_page_path: this.cid,
            transaction_type: 'SuperChat',
            value: this.selectSCPrice,
          });
        } else {
          this.$gtm.push({
            event: 'send_msg',
            device_type: navigator.userAgent,
            custom_page_path: this.cid,
          });
        }

        this.$emit('getUserItems');
      } catch (error) {
        const statusCode = error.response?.status;
        if (statusCode === 422) {
          this.$refs.messageInput.innerHTML = '';
          this.messageInput = '';
          alert(this.$t('streamingVideo.key014'));

          this.$emit('getUserItems');
        } else if (statusCode === HttpStatusCode.Unauthorized && typeof this.handleCompetedLogin === 'function') {
          this.handleCompetedLogin();
        } else {
          console.error('Unexpected error:', error);
          alert(this.$t('streamingVideo.key015'));
        }
      } finally {
        this.isSending = false;
      }
    },
    //Convert img tag
    transformMessageInput() {
      const rawHTML = this.$refs.messageInput.innerHTML.trim();
      const replacedHTML = rawHTML.replace(/<img\s+[^>]*alt=["']([^"']+)_(\d+)["'][^>]*>/g, (_, id, index) => `<[emoji="${id}" index=${index}/emoji]>`);

      const parser = new DOMParser();
      const decodedHTML = parser.parseFromString(replacedHTML, 'text/html').documentElement.textContent;

      return decodedHTML;
    },
    toggleSticker() {
      if (!this.jwt) {
        this.$emit('toggle-showLoginModal');
        return;
      }

      if (this.isMobile) {
        this.showSuperChatArea = false;
      }
      this.showSticker = !this.showSticker;

      if (this.showSticker) {
        this.$gtm.push({
          event: 'sticker_button_click',
          custom_page_path: this.cid,
        });
      }

      this.$nextTick(() => {
        this.$refs.chatMessageComponent.scrollToBottom();
      });
    },
    toggleSuperChat() {
      if (!this.jwt) {
        this.$emit('toggle-showLoginModal');
        return;
      }

      if (this.isMobile) {
        this.showSticker = false;
      }
      this.showSuperChatArea = !this.showSuperChatArea;

      if (this.showSuperChatArea) {
        this.$gtm.push({
          event: 'sc_button_click',
          device_type: navigator.userAgent,
          custom_page_path: this.cid,
        });
      }

      this.$nextTick(() => {
        this.$refs.chatMessageComponent.scrollToBottom();
      });
    },
    updateSelectedSCId(newId, price) {
      this.selectSCId = newId;
      this.selectSCPrice = price;

      this.$gtm.push({
        event: 'sc_amount_adjust',
        device_type: navigator.userAgent,
        custom_page_path: this.cid,
      });
    },
    toggleShowChatRoom() {
      this.$emit('toggle-showChatRoom');
    },
    // check forbiddenWords
    isWordingValid(value) {
      const lowerCaseValue = value.toLowerCase();

      for (let word of this.forbiddenWords) {
        if (lowerCaseValue.includes(word)) {
          return false;
        }
      }
      return true;
    },
  },
});
</script>

<style lang="scss"></style>
