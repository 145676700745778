<template>
  <transition name="fade">
    <section class="cookie" v-if="showCookieConsent">
      <p>
        {{ $t('common.cookie_key001') }}
        <a :href="this.cookieUrl[currentLanguage] || link.url['default']" target="_blank">
          {{ $t('common.footer_key003') }}
        </a>
      </p>
      <div class="cookie-consent__actions">
        <button @click="rejectCookies" class="cookie-consent__button reject">
          {{ $t('common.cookie_key002') }}
        </button>
        <button @click="acceptCookies" class="cookie-consent__button agree">
          {{ $t('common.cookie_key003') }}
        </button>
      </div>
    </section>
  </transition>
</template>
<script>
export default {
  name: 'CookieAgree',
  components: {},
  data() {
    return {
      showCookieConsent: false,
      cookieUrl: {
        en: 'https://www.htc.com/us/terms/cookies/',
        zh_tw: 'https://www.htc.com/tw/terms/cookies/',
        ja: 'https://www.htc.com/jp/terms/cookies/',
        default: 'https://www.htc.com/us/terms/cookies/',
      },
    };
  },
  props: {},
  computed: {},
  mounted() {
    this.checkCookieConsent();
  },
  methods: {
    checkCookieConsent() {
      const cookieConsent = localStorage.getItem('cookieConsent');
      if (cookieConsent !== null && cookieConsent !== '') {
        this.showCookieConsent = false;
      } else {
        this.showCookieConsent = true;
      }
    },
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'accepted');
      location.reload();
    },
    rejectCookies() {
      localStorage.setItem('cookieConsent', 'rejected');
      this.showCookieConsent = false;
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/pages/index.scss';
</style>
