<template>
  <div class="mobile-header">
    <div class="top-nav">
      <router-link :to="`/${currentLanguage}`">
        <div class="BEATDAY-logo">
          <img src="@/assets/img/headerFooter/BEATDAY_white.svg" alt="BEATDAY-Logo" />
        </div>
      </router-link>
      <div class="right-flex">
        <router-link :to="`/${currentLanguage}/cart`" class="shopping-cart">
          <i class="bi bi-cart3"></i>
          <div class="cart_count" v-if="cartCount">
            <p>{{ cartCount }}</p>
          </div>
        </router-link>
        <div class="menu_nav_icon">
          <button @click="toggleButton" :aria-pressed="isMenuOpen">
            <svg aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
              <rect width="18" height="1.5" fill="red" ry="0.75" x="3" y="6.25" />
              <rect width="18" height="1.5" fill="red" ry="0.75" x="3" y="11.25" />
              <rect width="18" height="1.5" fill="red" ry="0.75" x="3" y="16.25" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="marquee">
      <Marquee />
    </div>
    <Transition name="slide-fade" v-if="isMenuOpen" appear>
      <div class="drawer-nav">
        <div class="login-success login" v-if="loginSuccess">
          <router-link :to="`/${currentLanguage}/member`" class="button">
            <i class="bi bi-person-fill"></i>
            <span>{{ nickName }}</span>
          </router-link>
          <div @click="logOut" class="logOut">
            {{ $t('common.header_key004') }}
          </div>
        </div>
        <nav class="menu">
          <ul class="level-one">
            <li v-for="(menuItem, index) in mergedMenuData" :key="index" class="level-one-item">
              <router-link :to="`/${currentLanguage}/${menuItem.route}`" class="routerLink" v-if="menuItem.route !== 'NaN'">
                <p>
                  <span>{{ menuItem.label }}</span>
                  <img src="@/assets/img/cartOrder/arrow.svg" alt="" v-if="menuItem.submenu" class="arrow-icon" :class="{ 'arrow-rotated': menuItem.isOpen }" />
                </p>
              </router-link>
              <a @click="toggleSubmenu(index)" v-else>
                <p>
                  <span>{{ menuItem.label }}</span>
                  <img src="@/assets/img/cartOrder/arrow.svg" alt="" v-if="menuItem.submenu" class="arrow-icon" :class="{ 'arrow-rotated': menuItem.isOpen }" />
                </p>
              </a>
              <ul v-if="menuItem.submenu" class="level-two" :class="menuItem.isOpen ? 'open' : ''">
                <li v-for="(subItem, subIndex) in menuItem.submenu" :key="subIndex" class="level-two-item" @click="clickLink">
                  <router-link :to="`/${currentLanguage}/${subItem.route}`">
                    <p>{{ subItem.label }}</p>
                    <div class="submenu_icon"></div>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <div class="login" v-if="!loginSuccess">
          <router-link :to="`/${currentLanguage}/member-login/login`" class="button" @click="clickLink">
            {{ $t('common.header_key003') }}
          </router-link>
        </div>
        <div class="social-links">
          <div class="follow-us">Follow Us</div>
          <div class="links">
            <a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053" target="_blank" class="line">
              <img src="@/assets/img/headerFooter/Line.svg" alt="" />
            </a>
            <a href="https://www.facebook.com/beatday.music" target="_blank" class="FB">
              <img src="@/assets/img/headerFooter/facebook.svg" alt="" />
            </a>
            <a href="https://www.instagram.com/beatday.music/" target="_blank" class="instagram">
              <img src="@/assets/img/headerFooter/Instagram.svg" alt="" />
            </a>
            <a href="https://twitter.com/beatday_verse" target="_blank" class="twitter">
              <img src="@/assets/img/headerFooter/twitter.svg" alt="" />
            </a>
            <a href="https://www.youtube.com/channel/UCtZsfDkAPwPqOzlENXSjFPA" target="_blank" class="youtube">
              <img src="@/assets/img/headerFooter/youtube.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import Marquee from '@/components/Marquee.vue';
export default {
  components: {
    Marquee,
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    memberMenu: {
      type: Array,
      required: true,
    },
    cartCount: {
      type: Number,
      required: null,
    },
    jwt: {
      type: String,
      required: true,
    },
    nickName: {
      type: String,
      default: 'Unknown',
    },
    loginSuccess: {
      type: Boolean,
      required: true,
    },
    handleClick: {
      type: Function,
      required: true,
    },
    logOut: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      clickEnabled: true,
      mergedMenuData: [],
    };
  },
  mounted() {
    // 在 mounted 钩子中执行合并操作
    this.updateMergedMenu();
  },
  methods: {
    toggleButton() {
      this.clickEnabled = false;

      if (this.isMenuOpen) {
        document.body.style.overflow = '';
        this.isMenuOpen = false;
      } else {
        document.body.style.overflow = 'hidden';
        this.isMenuOpen = true;
      }

      setTimeout(() => {
        this.clickEnabled = true;
      }, 1000);
    },
    clickLink() {
      this.clickEnabled = false;
      this.isMenuOpen = false;
      document.body.style.overflow = '';
      setTimeout(() => {
        this.clickEnabled = true;
      }, 1000);
    },
    updateMergedMenu() {
      if (this.loginSuccess) {
        this.mergedMenuData = [
          ...this.memberMenu.map((item) => ({
            ...item,
            isOpen: item.submenu ? false : undefined,
          })),
          ...this.menuItems.map((item) => ({
            ...item,
            isOpen: item.submenu ? false : undefined,
          })),
        ];
      } else {
        this.mergedMenuData = this.menuItems.map((item) => ({
          ...item,
          isOpen: item.submenu ? false : undefined,
        }));
      }
    },
    toggleSubmenu(index) {
      const menuItem = this.mergedMenuData[index];
      if (menuItem && menuItem.submenu !== undefined) {
        menuItem.isOpen = !menuItem.isOpen;
      }
    },
  },
};
</script>
