import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import store from './vuex';
import i18n from './assets/i18n';
import { globalMixins, StatusCodes } from './assets/i18n/routerLinkLang.js'; // 假設這是您的全局混入
import './assets/styles/reset.scss';
import './assets/styles/global.scss';
import vueCountryRegionSelect from 'vue3-country-region-select';
import { registerIVSTech, registerIVSQualityPlugin } from 'amazon-ivs-player';
import videojs from 'video.js';
import VueGtm from '@gtm-support/vue-gtm';

const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();

registerIVSTech(videojs, {
  wasmWorker: createAbsolutePath('./ivs-files/amazon-ivs-wasmworker.min.js'),
  wasmBinary: createAbsolutePath('./ivs-files/amazon-ivs-wasmworker.min.wasm'),
});

registerIVSQualityPlugin(videojs);

// 創建 vue 應用實例
const app = createApp(App);
const globalData = {
  // 全局變量
  Version: 'release',
};

// 使用 i18n
app.use(i18n);

app.config.globalProperties.$globalData = globalData;
app.config.globalProperties.$statusCodes = StatusCodes;
app.config.globalProperties.$cloudFront = process.env.VUE_APP_CLOUD_FRONT;

//GTM
const GTM_ID = process.env.VUE_APP_Google_Tag_Manager_ID;
const isProduction = process.env.NODE_ENV === 'production';
const cookieConsent = localStorage.getItem('cookieConsent');
const isGtmEnabled = cookieConsent === 'accepted';

app.use(VueGtm, {
  id: GTM_ID,
  vueRouter: router,
  enabled: isGtmEnabled,
  debug: !isProduction,
  loadScript: true,
});

// 使用路由
app.use(router);

// 使用 Vuex
app.use(store);

// 全局混入，用于在初始化时设置默认语言
app.mixin(globalMixins);

app.use(vueCountryRegionSelect);
// 挂载应用
app.mount('#app');
