<template>
  <div class="program-info" v-show="!isMobile || !showMoreInfo" v-if="streamingInfo">
    <!-- 節目標題 -->
    <h2 class="lineTitle title" @click="$emit('toggleShowMore')">{{ streamingInfo.title }}</h2>

    <!-- 節目簡述 -->
    <div class="describe" @click="$emit('toggleShowMore')">
      <p class="description">{{ firstLineDescription }}</p>
      <button v-if="this.streamingInfo.description.includes('\n')" class="expand">{{ $t('streamingVideo.key011') }}</button>
    </div>

    <!-- 聊天室按鈕 -->
    <button v-if="isMobile" @click="$emit('toggleShowChatRoom')" class="chatRoom-btn">
      <div class="titleChatRoom">
        <p>{{ $t('streamingVideo.key008') }}</p>
        <p class="expand">{{ $t('streamingVideo.key009') }}</p>
      </div>
      <p class="inputText">{{ $t('streamingVideo.key010') }}</p>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ProgramSummary',
  data() {
    return {};
  },
  props: {
    streamingInfo: Object,
    showMoreInfo: Boolean,
  },
  computed: {
    firstLineDescription() {
      const firstLine = this.streamingInfo.description.includes('\n') ? this.streamingInfo.description.split('\n')[0] : this.streamingInfo.description;
      return firstLine;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
