<template>
  <div class="marquee" v-show="parsedMessages.length > 0">
    <div class="marquee-content" ref="marqueeContent" :style="marqueeStyle">
      <p v-for="(formattedMessage, index) in parsedMessages" :key="index" v-html="formattedMessage"></p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'MarqueeText',
  data() {
    return {
      contentWidth: 0,
      animationDuration: 10,
      marquee: {},
      parsedMessages: [],
    };
  },
  computed: {
    marqueeStyle() {
      return {
        animationDuration: `${this.animationDuration}s`,
      };
    },
    ...mapState(['marquees']),
  },
  watch: {
    marquees: {
      handler(value) {
        this.parsedMessages = value.map((item) => {
          const message = item.message || '';
          return this.parsedBBCode(message);
        });

        // 計算內容的寬度
        this.$nextTick(() => {
          if (this.$refs.marqueeContent) {
            this.contentWidth = this.$refs.marqueeContent.offsetWidth; // 獲取內容的寬度
            this.animationDuration = this.calculateAnimationDuration(this.contentWidth);
          }
        });
      },
    },
  },
  async mounted() {
    this.updateMarquees(this.$route);
  },
  methods: {
    ...mapActions(['updateMarquees']),
    parsedBBCode(message) {
      return message
        .replace(/\[b\]/g, '<b>')
        .replace(/\[\/b\]/g, '</b>')
        .replace(/\[i\]/g, '<i>')
        .replace(/\[\/i\]/g, '</i>')
        .replace(/\[u\]/g, '<u>')
        .replace(/\[\/u\]/g, '</u>')
        .replace(/\[color=(.*?)\]/g, '<span style="color:$1;" class="font">')
        .replace(/\[\/color\]/g, '</span>');
    },
    calculateAnimationDuration(width) {
      const viewportWidth = window.innerWidth;
      const baseSpeed = 10;
      const duration = (width / viewportWidth) * baseSpeed;
      return duration > baseSpeed ? duration : baseSpeed;
    },
  },
};
</script>

<style scoped>
.marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  background: rgb(23, 23, 23, 0.6);
  backdrop-filter: blur(5px);
  padding: 5px 0;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%;
  animation: marquee linear infinite;
}
.marquee-content p {
  display: inline;
  padding: 0 20px;
}
span.font {
  font-size: 10px;
}
.marquee-content span b {
  font-weight: bold !important;
}
.marquee-content span i {
  font-style: italic !important;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@media (max-width: 1200px) {
}

@media (max-width: 991px) {
}

@media (max-width: 576px) {
}

@media (max-width: 414px) {
  .marquee-content {
    span {
      font-size: 10px;
    }
    p,
    span,
    i,
    u {
      font-size: 10px;
    }
    span.font {
      font-size: 10px;
    }
  }
}
</style>
