<template>
  <!-- chat -->
  <div class="chat-messages">
    <div class="SC-detail-box disable-click" v-if="MsgConnectionFailed"></div>
    <div class="super-chat-message" @click="closeSCDetail">
      <div class="SCMessages-bar-renderer" v-if="SCMessages.length > 0">
        <swiper :slidesPerView="'auto'" :pagination="{ clickable: true }" :space-between="10" class="swiper-container" ref="swiper">
          <swiper-slide
            v-for="(SCMessage, index) in SCMessages.slice(0, 20)"
            :key="index"
            @click="selectSCMessage(SCMessage, $event)"
            :class="['SCMessage-short', 'SC-bar-bg', `background-${SCMessage.sc}`, { 'SCMessage-short-disable': MsgConnectionFailed }]"
          >
            <img :src="getImageUrl(SCMessage.uid)" @error="hideImage" alt="" class="user-img" />
            <p class="user-name">{{ SCMessage.nick || 'Unknown' }}</p>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <div class="message-area">
      <div class="SC-detail-box connection-failed" v-if="MsgConnectionFailed">
        <p>{{ $t('streamingVideo.key016') }}</p>
      </div>
      <div class="SC-detail-box" v-if="selectedSCMessage != null" @click="closeSCDetail">
        <div class="SC-message-details" @click.stop>
          <i class="bi bi-x leave-icon" @click="closeSCDetail"></i>
          <div :class="['superChat-massage', 'SC-bar-bg', `background-${selectedSCMessage.sc}`]">
            <div class="SC-message-user-info">
              <img :src="getImageUrl(selectedSCMessage.uid)" @error="hideImage" alt="" class="SC-message-img" />
              <div class="user-info">
                <p class="name">{{ selectedSCMessage.nick || 'Unknown' }}</p>
                <p class="price">NT$ {{ selectedSCMessage.price }}</p>
              </div>
            </div>
            <div class="SC-message-msg-info">
              <p>
                <span v-for="(block, i) in selectedSCMessage.msg" :key="i">
                  <template v-if="block.type === 'text'">
                    {{ block.content }}
                  </template>
                  <template v-else-if="block.type === 'emoji'">
                    <img :src="block.src" :alt="block.alt" :class="{ singleSticker: block.isSingleSticker }" />
                  </template>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="message-box" ref="chatMessages" @scroll="handleScroll">
        <div v-for="(message, index) in chatMessages" :key="index" :class="['message', { 'my-message': message.uid === myUid }]">
          <div :class="['superChat-massage', 'SC-bar-bg', `background-${message.sc}`]" v-if="message.sc !== ''">
            <div class="SC-message-user-info">
              <img :src="getImageUrl(message.uid)" @error="hideImage" alt="" class="SC-message-img" />
              <div class="user-info">
                <p class="name">{{ message.nick || 'Unknown' }}</p>
                <p class="price"><span>NT$ </span>{{ message.price }}</p>
              </div>
            </div>
            <div class="SC-message-msg-info">
              <p>
                <span v-for="(block, i) in message.msg" :key="i">
                  <template v-if="block.type === 'text'">
                    {{ block.content }}
                  </template>
                  <template v-else-if="block.type === 'emoji'">
                    <img :src="block.src" :alt="block.alt" :class="{ singleSticker: block.isSingleSticker }" />
                  </template>
                </span>
              </p>
            </div>
          </div>
          <div class="default-message" v-else>
            <div class="float-left">
              <div class="user-image">
                <img :src="getImageUrl(message.uid)" @error="hideImage" alt="" />
              </div>
              <span class="user-name">{{ message.nick || 'Unknown' }}</span>
            </div>
            <div class="float-right">
              <p>
                <span v-for="(block, i) in message.msg" :key="i">
                  <template v-if="block.type === 'text'">
                    {{ block.content }}
                  </template>
                  <template v-else-if="block.type === 'emoji'">
                    <img :src="block.src" :alt="block.alt" :class="{ singleSticker: block.isSingleSticker }" />
                  </template>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isAtBottom" class="scroll-to-bottom">
        <div class="arrow-icon" @click="scrollToBottom">
          <i class="bi bi-arrow-down-short"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { streamingGetChat, streamingGetSCMsg } from '@/api/streamingService.js';

export default defineComponent({
  name: 'ChatMessage',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {},
  data() {
    return {
      chatMessages: [],
      latestTs: null,
      updateInterval: null,
      isGettingChatMsg: false,
      SCMessages: [],
      isAtBottom: true,
      selectedSCMessage: null,
      MsgConnectionFailed: false,
    };
  },
  computed: {
    selectedMessage() {
      return this.selectedSCIndex !== null ? this.SCMessages[this.selectedSCIndex] : null;
    },
    myUid() {
      return this.$store.state.userId || '';
    },
  },
  mounted() {
    this.getChatMsg(true);
    this.getSCMessages();
    this.startChatUpdates();
  },
  beforeUnmount() {
    this.stopChatUpdates();
  },
  methods: {
    async getChatMsg(isInitial = false) {
      if (this.isGettingChatMsg) {
        return;
      }

      this.isGettingChatMsg = true;

      try {
        const cid = this.$route.params.cid;
        const response = await streamingGetChat(cid, isInitial ? null : this.latestTs);
        const responseData = response.data.data;
        const messages = responseData.msg;
        this.MsgConnectionFailed = false;

        this.latestTs = responseData.ts;

        if (responseData.count === 0) {
          this.updateSCMessages();
          return;
        }

        // General message emoji conversion
        const processedMessages = messages.map((message) => {
          return {
            ...message,
            msg: this.replaceEmojiWithImage(message.msg || ''),
          };
        });

        this.chatMessages = this.processMessages(this.chatMessages, processedMessages);

        if (isInitial) {
          this.SCMessages = await this.getSCMessages();
        } else {
          this.updateSCMessages(processedMessages);
        }

        // Scroll to the bottom on first into
        if (isInitial) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        } else if (this.isAtBottom) {
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      } catch (error) {
        console.error('Error fetching chat messages:', error);
        this.MsgConnectionFailed = true;
      } finally {
        this.isGettingChatMsg = false;
      }
    },
    async getSCMessages() {
      try {
        const cid = this.$route.params.cid;
        const response = await streamingGetSCMsg(cid);
        const SCMessages = response.data.data || [];

        return SCMessages.map((message) => ({
          ...message,
          msg: this.replaceEmojiWithImage(message.msg || ''),
        }));
      } catch (error) {
        console.error('Error fetching SC messages:', error);
        return [];
      }
    },
    processMessages(oldMessages, newMessages) {
      const safeOldMessages = Array.isArray(oldMessages) ? oldMessages : [];
      const safeNewMessages = Array.isArray(newMessages) ? newMessages : [];

      if (safeOldMessages.length + safeNewMessages.length > 200) {
        const excess = safeOldMessages.length + safeNewMessages.length - 200;
        safeOldMessages.splice(0, excess);
      }
      const updatedMessages = [...safeOldMessages, ...safeNewMessages];

      return updatedMessages;
    },
    updateSCMessages(newMessages = []) {
      const currentTime = Date.now();
      const safeNewMessages = Array.isArray(newMessages) ? newMessages : [];
      const scMessagesToAdd = safeNewMessages.filter((message) => message.sc && message.sc.trim() !== '');
      this.SCMessages = this.SCMessages.filter((message) => !message.expiredTime || message.expiredTime > currentTime);
      this.SCMessages.unshift(...scMessagesToAdd.reverse());
    },

    replaceEmojiWithImage(content) {
      if (typeof content !== 'string') {
        return [];
      }

      const emojiRegex = /<\[emoji="([^"]+)"\sindex=(\d+)\/emoji]>/g;
      const result = [];
      let lastIndex = 0;

      const emojiMatches = [...content.matchAll(emojiRegex)];
      const isSingleSticker = emojiMatches.length === 1 && content.trim() === emojiMatches[0][0];

      content.replace(emojiRegex, (fullMatch, emojiCode, index, offset) => {
        if (offset > lastIndex) {
          result.push({ type: 'text', content: content.slice(lastIndex, offset) });
        }

        result.push({
          type: 'emoji',
          src: `${this.$cloudFront}/Image/Public/chat_stickers/${emojiCode}/${index}.png`,
          alt: emojiCode,
          isSingleSticker,
        });

        lastIndex = offset + fullMatch.length;
      });

      if (lastIndex < content.length) {
        result.push({ type: 'text', content: content.slice(lastIndex) });
      }

      return result;
    },

    startChatUpdates() {
      if (!this.updateInterval) {
        this.updateInterval = setInterval(() => {
          this.getChatMsg(false);
        }, 5000);
      }
    },

    // stopChatUpdates for Unmounted
    stopChatUpdates() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }
    },

    resetChatUpdates() {
      this.stopChatUpdates();
      this.getChatMsg(false);
      this.startChatUpdates();
    },

    handleScroll() {
      const chatMessages = this.$refs.chatMessages;

      // might not exist when the page is unmounted
      if (chatMessages) {
        const isBottom = Math.abs(chatMessages.scrollHeight - chatMessages.scrollTop - chatMessages.clientHeight) < 10;

        this.isAtBottom = isBottom;
      }
    },
    scrollToBottom() {
      this.handleScroll();
      const chatMessages = this.$refs.chatMessages;
      const tryScroll = () => {
        if (!this.isAtBottom) {
          chatMessages.scrollTo({
            top: chatMessages.scrollHeight,
            behavior: 'smooth',
          });
          setTimeout(() => tryScroll(), 200);
        } else {
          this.isAtBottom = true;
        }
      };

      tryScroll();
    },
    selectSCMessage(SCMessage, event) {
      event.stopPropagation();
      this.selectedSCMessage = SCMessage;
    },
    closeSCDetail() {
      this.selectedSCMessage = null;
    },
    getImageUrl(uid) {
      const path = process.env.VUE_APP_USER_AVATAR_URL;
      return `${path}${uid}.png`;
    },
    hideImage(event) {
      event.target.src = 'https://d2uz35uzla1hiz.cloudfront.net/UserIcon/ch.png';
    },
  },
});
</script>

<style lang="scss"></style>
