<template>
  <header id="header">
    <DesktopHeader
      :jwt="jwt"
      :nick-name="nickName"
      :login-success="loginSuccess"
      :menu-items="menuItems"
      :member-menu="memberMenu"
      :cart-count="cartCount"
      :handle-click="handleClick"
      :log-out="logOut"
      v-if="!isMobile"
    />

    <MobileHeader
      :jwt="jwt"
      :nick-name="nickName"
      :login-success="loginSuccess"
      :menu-items="menuItems"
      :member-menu="memberMenu"
      :cart-count="cartCount"
      :handle-click="handleClick"
      :log-out="logOut"
      v-else
    />
  </header>
  <div class="marquee" v-if="!isMobile">
    <Marquee />
  </div>
</template>

<script>
import Marquee from '@/components/Marquee.vue';
import DesktopHeader from '@/components/header/headerDesktop.vue';
import MobileHeader from '@/components/header/headerMobile.vue';
import { getCartItems } from '@/localStorage/cartStorage';

export default {
  name: 'AppHeader',
  components: {
    DesktopHeader,
    MobileHeader,
    Marquee,
  },
  data() {
    return {
      cartCount: null,
      menuItems: [
        {
          label: this.$t('common.header_key002'),
          type: 'router',
          route: 'products',
        },
        {
          label: this.$t('common.header_key009'),
          type: 'router',
          route: 'NaN',
          submenu: [
            {
              label: this.$t('faq.key002'),
              route: 'faq/account-issues',
            },
            {
              label: this.$t('faq.key003'),
              route: 'faq/ticket-issues',
            },
            {
              label: this.$t('faq.key004'),
              route: 'faq/redemption-guide',
            },
            {
              label: this.$t('faq.key005'),
              route: 'faq/beatday-usage',
            },
          ],
        },
        {
          label: this.$t('common.header_key010'),
          type: 'router',
          route: 'NaN',
          submenu: [
            {
              label: this.$t('about-us.key002'),
              route: 'aboutUs/about-beatday',
            },
            {
              label: this.$t('about-us.key003'),
              route: 'aboutUs/business-cooperation',
            },
            {
              label: this.$t('about-us.key004'),
              route: 'aboutUs/contact-us',
            },
          ],
        },
      ],
      memberMenu: [
        {
          label: this.$t('common.header_key005'),
          type: 'router',
          route: 'NaN',
          submenu: [
            {
              label: this.$t('common.header_key008'),
              route: 'member/member-information',
            },
            {
              label: this.$t('common.member_nav_key005'),
              route: 'member/reset-password',
            },
            {
              label: this.$t('common.header_key006'),
              route: 'member/ticket-section',
            },
            {
              label: this.$t('common.header_key007'),
              route: 'member/serial-number',
            },
            {
              label: this.$t('my-orders.key001'),
              route: 'member/my-orders',
            },
          ],
        },
      ],
    };
  },
  computed: {
    jwt() {
      return this.$store.state.jwt || '';
    },
    nickName() {
      return this.$store.state.nickName || 'Unknown';
    },
    loginSuccess() {
      return !!this.$store.state.jwt;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('carItemsChanged', this.handleCartItemChange);
    this.getTotalCartCount();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.body.style.overflow = '';
  },
  methods: {
    handleCartItemChange() {
      this.getTotalCartCount();
    },
    getTotalCartCount() {
      const cartItems = getCartItems() || [];
      const totalCount = cartItems.reduce((sum, item) => sum + item.quantity, 0);
      this.cartCount = totalCount;
    },
    handleClick(route) {
      if (route === 'NaN') {
        event.preventDefault();
      } else {
        document.body.style.overflow = '';
        this.$router.push(`/${this.currentLanguage}/${route}`);
      }
    },
    logOut() {
      this.$store.dispatch('logout');
      this.$router.push(`/${this.currentLanguage}/member-login/login`);
    },
    handleResize() {
      this.$store.dispatch('updateIsMobile');
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/components/header.scss';
</style>
